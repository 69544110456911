<div class="summary d-flex flex-column">
  <div class="py-5 summary-logo">
    <app-logo></app-logo>
  </div>
  <div class="summary-content">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="paymentStatusTypes.success">
        <div class="text-center">
          <h2>{{ 'app.page.payment.complete.first-line' | translate }}</h2>
          <p [innerHTML]="'app.page.payment.complete.seccond-line' | translate"></p>
          <h2>{{orderNumber}}</h2>
        </div>

        <div class="d-flex flex-column summary-list mx-5 px-2">
          <ng-container *ngFor="let item of items">
            <div class="d-inline-flex justify-content-between gap-1">
              <div class="d-inline-flex gap-1">
                <span>{{item.quantity | quantity}}</span><span>{{item.name}}</span>
              </div>
              <div class="text-right text-nowrap">
                <ng-container *ngTemplateOutlet="itemTotalPriceTemplate; context: { item: item }"></ng-container>
              </div>
            </div>
          </ng-container>
        </div>

      </ng-container>
      <ng-container *ngSwitchCase="paymentStatusTypes.cancel">
        <div class="text-center">
          <h2>{{ 'app.page.payment.cancel.first-line' | translate }}</h2>
          <p>{{ 'app.page.payment.cancel.seccond-line' | translate }}</p>
        </div>

      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="text-center">
          <h2>{{ 'app.page.payment.pending.first-line' | translate }}</h2>
          <p>{{ 'app.page.payment.pending.seccond-line' | translate }}</p>
        </div>

        <button *ngIf="currentTry === maxTriesAmount" class="mx-0" mat-button color="primary"
                (click)="currentTry = 1; checkOrderStatus()">
          {{ 'button.check-again' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="footer footer-extra row">
  <div class="col-12">
    <button class="action-button mx-0" mat-raised-button color="primary" (click)="createNewOrder()">
      {{ 'button.create-new-order' | translate }}
    </button>
  </div>
</div>

<ng-template #itemTotalPriceTemplate let-item="item">
  <ng-container *ngIf="!item.discountPrice else diffPriceTemplate">
    {{ item.price * item.quantity | money}}
  </ng-container>
  <ng-template #diffPriceTemplate>
    <span class="discount-price-box"><span
            class="before">{{ item.discountPrice.before * item.quantity | money}}</span>&nbsp;<span
            class="after">{{ item.discountPrice.after * item.quantity | money}}</span></span>
  </ng-template>
</ng-template>