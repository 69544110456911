import { Component, Input, TemplateRef } from '@angular/core';
import { CinemaViewModel, ScreeningDetailsViewModel } from '@dinein-lib/restapi-plugin';

@Component({
  selector: 'app-screening-details',
  templateUrl: './screening-details.component.html',
  styleUrls: ['./screening-details.component.scss'],
})
export class ScreeningDetailsComponent {
  @Input() public details: ScreeningDetailsViewModel;
  @Input() public cinema: CinemaViewModel;

  @Input() public customPosterTemplate!: TemplateRef<any>;
  @Input() public customContentTemplate!: TemplateRef<any>;
  @Input() public customSeatsTemplate!: TemplateRef<any>;
}
