import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { OrderApiModel } from '../models/api-model/order/order.api.model';
import { CreateNewOrderRequest } from '../models/create-new-order-request.model';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { CateringResponseModel } from '../models/catering.response.model';
import { NutritionalInfoModel } from '../models/nutritional-info.response.model';
import { PaymentMethodResponseModel } from '../models/response/payment-method.response.model';
import { PaymentApiModel } from '../models/api-model/sales-document/payment.api.model';
import { PaymentProviderConfigRequestModel } from '../models/request/payment-provider-config.request.model';
import { PaymentConfigApiModel } from '../models/api-model/payment/config/payment.config.api.model';
import { plainToInstance } from 'class-transformer';
import { PaymentProviderPayMethodApiModel } from '../models/api-model/payment-provider-pay-method.response.model';
import { PaymentProviderPayMethodRequestModel } from '../models/request/payment-provider-pay-method.request.model';
import { UpdateOrderRequest } from '../models/update-order-request.model';
import { TableApiModel } from '../models/api-model/table/table.api.model';
import { POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS } from '../injection.tokens';
import { IRestApiOptions } from '../interfaces/restapi-options';
import { SeatRequestRequestModel } from '../models/api-model/seat/seat.request.model';

export class TokenPaymentResponse {
  public jwt: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(@Inject(POSITIVECINEMA_RESTAPI_PLUGIN_OPTIONS) protected options: IRestApiOptions, private http: HttpClient) {}

  public static cacheModify(key: string, responseData: object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }

  public getOrder(cinemaId: string, orderId: string): Observable<OrderApiModel> {
    return this.http.get<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}`);
  }

  public postOrder(cinemaId: string, sourceOrderId: string = null): Observable<OrderApiModel> {
    const createNewOrderRequest: CreateNewOrderRequest = {
      sourceOrderId: sourceOrderId,
    };

    return this.http.post<OrderApiModel>(`/cinema/${cinemaId}/order`, createNewOrderRequest).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public putOrder(cinemaId: string, orderId: string, order: UpdateOrderRequest) {
    return this.http.put<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}`, order).pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  silentDeleteOrder(cinemaId: string, orderId: string): boolean {
    let apiUrl: string = this.options.environment.api.url;
    if (apiUrl.endsWith('/') === false) {
      apiUrl = apiUrl + '/';
    }

    const endpoint = `cinema/${cinemaId}/order/${orderId}/delete`;
    return navigator.sendBeacon(apiUrl + endpoint);
  }

  public deleteOrder(cinemaId: string, orderId: string) {
    return this.http.delete(`/cinema/${cinemaId}/order/${orderId}`).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public postOrderClose(cinemaId: string, orderId: string, forcePaymentConfirmed = true) {
    return this.http
      .post(`/cinema/${cinemaId}/order/${orderId}/close`, forcePaymentConfirmed ? { paymentConfirmed: true } : null)
      .pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public getOrderPaymentMethod(cinemaId: string, orderId: string) {
    return this.http.get<PaymentMethodResponseModel[]>(`/cinema/${cinemaId}/order/${orderId}/payment/method`);
  }

  public getOrderPayment(orderPaymentRequest: OrderPaymentRequestModel) {
    const options: any = {
      params: {},
    };

    if (orderPaymentRequest.paymentChannel) {
      options.params.channel = orderPaymentRequest.paymentChannel;
    }

    if (orderPaymentRequest.continueUrl) {
      options.params.continueUrl = orderPaymentRequest.continueUrl;
    }

    if (orderPaymentRequest.paymentData && typeof orderPaymentRequest.paymentData === 'object') {
      for (const key of Object.keys(orderPaymentRequest.paymentData)) {
        options.params['paymentData[' + key + ']'] = orderPaymentRequest.paymentData[key];
      }
    }

    if (orderPaymentRequest.intPayMethodType) {
      options.params.intPayMethodType = orderPaymentRequest.intPayMethodType;
    }

    if (orderPaymentRequest.intPayMethodValue) {
      options.params.intPayMethodValue = orderPaymentRequest.intPayMethodValue;
    }

    if (orderPaymentRequest.savePayment) {
      options.params.savePayment = 'true';
    }

    if (orderPaymentRequest.paymentToken) {
      options.params.paymentToken = orderPaymentRequest.paymentToken;
    }

    return this.http.get<PaymentApiModel>(
      `/cinema/${orderPaymentRequest.cinemaId}/order/${orderPaymentRequest.orderId}/payment${
        orderPaymentRequest.paymentProviderIdentifier ? '/' + orderPaymentRequest.paymentProviderIdentifier : ''
      }`,
      <object>options
    );
  }

  public getPayment(cinemaId: string, orderId: string, token: string = null) {
    const options: any = {
      context: {},
    };

    if (token === null) {
      options.context = { gen: true, orderId: orderId };
    } else {
      options.context = { data: token };
    }

    return this.http.get<TokenPaymentResponse>(`/cinema/${cinemaId}/payment`, options);
  }

  @Cacheable()
  public getOrderFb(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringResponseModel> {
    let params = new HttpParams();
    //params = params.append('forceReload', 'true');

    if (screenGroupId !== null) {
      params = params.append('screenGroupId', screenGroupId);
    }

    const path = orderId ? `/cinema/${cinemaId}/order/${orderId}/fb` : `/cinema/${cinemaId}/fb`;

    return this.http.get<CateringResponseModel>(path, { params: params });
  }

  public deleteOrderItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderApiModel> {
    return this.http.delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/item/${itemId}`).pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  // /api/cinema/{cinemaId}/fb/nutritionalinfo
  @Cacheable()
  public getFbNutritionalInfo(cinemaId: string) {
    return this.http.get<NutritionalInfoModel[]>(`/cinema/${cinemaId}/fb/nutritionalinfo`);
  }

  public getPaymentProviderConfig(request: PaymentProviderConfigRequestModel) {
    const options: any = {
      params: {},
    };

    if (request.paymentChannel !== null) {
      options.params.channel = request.paymentChannel;
    }

    return this.http
      .get<PaymentConfigApiModel>(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProviderIdentifier}/config`, options)
      .pipe(map((res) => plainToInstance(PaymentConfigApiModel, res as object)));
  }

  @Cacheable()
  public getPaymentProviderPayMethodCollection(request: PaymentProviderPayMethodRequestModel): Observable<Array<PaymentProviderPayMethodApiModel>> {
    return this.http
      .get<Array<PaymentProviderPayMethodApiModel>>(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProvider}/methods`, {
        params: {
          channel: request.paymentChannel,
        },
      })
      .pipe(
        map((res) =>
          plainToInstance(PaymentProviderPayMethodApiModel, res as object[], {
            strategy: 'excludeAll',
          })
        )
      );
  }

  public putOrderAgreements(cinemaId: string, orderId: string, agreements: string[]) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/agreements`, agreements);
  }

  public deleteOrderPayment(request: PaymentProviderPayMethodRequestModel): Observable<any> {
    return this.http.delete(`/cinema/${request.cinemaId}/order/${request.orderId}/payment/${request.paymentProvider}`, {
      params: {
        channel: request.paymentChannel,
      },
    });
  }

  public putTable(cinemaId: string, orderId: string, tableId: string): Observable<TableApiModel> {
    return this.http.put<TableApiModel>(`/cinema/${cinemaId}/order/${orderId}/table/${tableId}`, null);
  }

  public patchOrderFbItemSeat(cinemaId: string, orderId: string, body: SeatRequestRequestModel[]) {
    return this.http.patch(`/cinema/${cinemaId}/order/${orderId}/fbitem/seat`, body);
  }
}
