import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessagePageComponent, PageNotFoundComponent, RoutingHubComponent, ScannerComponent } from '@dinein-lib/core';
import { CustomOrderPageComponent } from './pages/order/order.component';
import { CustomProductsPageComponent } from './pages/products/products.component';
import { CustomPaymentPageComponent } from './pages/payment/payment.component';

const routes: Routes = [
  { path: 'payment/:status', component: CustomPaymentPageComponent },
  { path: 'payment', component: CustomPaymentPageComponent },
  { path: 'p', component: CustomPaymentPageComponent },
  { path: 'order-summary', component: CustomPaymentPageComponent },

  { path: 'offer', component: CustomProductsPageComponent },

  { path: 'init/:cinemaId/:params', component: RoutingHubComponent },
  { path: 'init/:cinemaId', component: RoutingHubComponent },

  { path: 'scanner', component: ScannerComponent },
  {
    path: 'order',
    component: CustomOrderPageComponent,
    data: { animation: 'isRight' },
  },
  { path: 'message/:id', component: MessagePageComponent },
  { path: 'index.html', component: PageNotFoundComponent },

  { path: ':cinemaId/:params', redirectTo: '/init/:cinemaId/:params' },
  { path: ':cinemaId', redirectTo: '/init/:cinemaId' },

  { path: '', component: RoutingHubComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
