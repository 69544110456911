import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { UserLoginRequestModel } from '../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../models/response/user-login.response.model';
import { UserTokenRequestModel } from '../models/request/user-token.request.model';
import { UserTokenResponseModel } from '../models/response/user-token.response.model';
import { UserAuthRequestModel } from '../models/request/user-auth.request.model';
import { UserApiModel } from '../models/api-model/user/user.api.model';
import { UserSetpasswordRequestModel } from '../models/request/user-setpassword.request.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(private http: HttpClient) {}

  public login(request: UserLoginRequestModel): Observable<UserLoginResponseModel> {
    return this.http
      .post('/user/login', instanceToPlain(request, { groups: ['login'] }))
      .pipe(map((result) => plainToInstance(UserLoginResponseModel, result as any)));
  }

  public loginByToken(request: UserTokenRequestModel): Observable<UserTokenResponseModel> {
    return this.http.post('/user/token', instanceToPlain(request)).pipe(map((result) => plainToInstance(UserTokenResponseModel, result as any)));
  }

  public doAuth(authProvider: string, request: UserAuthRequestModel): Observable<UserLoginResponseModel> {
    return this.http.post('/user/auth/' + authProvider, instanceToPlain(request)).pipe(map((result) => plainToInstance(UserLoginResponseModel, result as any)));
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserLoginResponseModel> {
    const provider = authProvider.toLowerCase();
    const body = {
      token: token,
      state: state,
    };

    return this.http.post('/user/auth/' + provider, body).pipe(map((result) => plainToInstance(UserLoginResponseModel, result as any)));
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    const options = {};
    if (redirectUrl) {
      options['params'] = {};
      options['params']['redirectUrl'] = redirectUrl;
    }

    return this.http.get('/user/auth/' + authProvider, options).pipe(map((result) => result['redirectUrl']));
  }

  public remindPassword(email: string): Observable<any> {
    return this.http.post(`/user/passwordtoken`, { email: email });
  }

  public setPassword(request: UserSetpasswordRequestModel): Observable<any> {
    return this.http.put(`/user/password`, instanceToPlain(request));
  }

  public getCurrent(params?): Observable<UserApiModel> {
    return this.http.get('/user', params).pipe(map((result) => plainToInstance(UserApiModel, result as any)));
  }

  public authMethod(): Observable<string[]> {
    return this.http.get('/user/auth/method').pipe(map((result) => result as string[]));
  }

  @Cacheable({
    cacheBusterObserver: UserHttpService.cacheBuster$,
    storageStrategy: LocalStorageStrategy,
    maxAge: 15000,
  })
  public getAccountItemsViaApiModel(): Observable<any> {
    return this.http.get('/user/accountItems');
  }
}
