import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CustomAppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as locales from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { CustomOrderPageComponent } from './pages/order/order.component';
import { CustomArticleCardComponent } from './components/article-card/article-card.component';
import { CustomSearchResultComponent } from './components/search-result/search-result.component';
import { CustomProductsPageComponent } from './pages/products/products.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomSearchFormComponent } from './components/search-form/search-form.component';
import { CustomPaymentPageComponent } from './pages/payment/payment.component';
import { preloadInstallUpdateServiceFactory } from './preload-service.factory';
import { CustomSimpleCountdownComponent } from './components/simple-countdown/simple-countdown.component';
import { AcceptLanguageInterceptor, ApiPluginModule, JwtTokenInterceptor, SocialAuthServiceConfig } from '@dinein-lib/restapi-plugin';
import { AppInstallUpdateService, CoreModule, WrapMissingTranslationHandler, storageKey } from '@dinein-lib/core';
import { SharedModule } from '@dinein-lib/shared';

function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}
defineLocales();

export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?t=' + Math.floor(new Date().getTime() / 3600000));
}

export function SocialAuthServiceConfigFactory() {
  return {
    autoLogin: false,
    providers: [],
    onError: (err) => {
      console.error(err);
    },
  } as SocialAuthServiceConfig;
}

@NgModule({
  declarations: [
    CustomAppComponent,
    CustomProductsPageComponent,
    CustomOrderPageComponent,
    CustomPaymentPageComponent,
    CustomArticleCardComponent,
    CustomSearchResultComponent,
    CustomSearchFormComponent,
    CustomSimpleCountdownComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: WrapMissingTranslationHandler,
      },
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ApiPluginModule.forRoot(
      {
        environment: environment,
        acceptLanguage: 'pl',
        storageKey: {
          lang: storageKey.lang,
          token: storageKey.token,
        },
      },
      [
        {
          provide: 'SocialAuthServiceConfig',
          useFactory: SocialAuthServiceConfigFactory,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AcceptLanguageInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtTokenInterceptor,
          multi: true,
        },
      ]
    ),
    CoreModule.forRoot(environment),
    SharedModule,
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AppInstallUpdateService],
      multi: true,
      useFactory: preloadInstallUpdateServiceFactory,
    },
  ],
  bootstrap: [CustomAppComponent],
})
export class AppModule {}
