import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderPageComponent, CheckListItemModel, OrderPaymentRequestModel } from '@dinein-lib/core';
import { PaymentProviderPayMethodApiModel, PaymentProviderPayMethodViewModel } from '@dinein-lib/restapi-plugin';
import { requiredFormArray, ValidatorHelper, MembershipLoginDialogComponent } from '@dinein-lib/shared';
import { of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-order-page',
  templateUrl: './order.component.html',
})
export class CustomOrderPageComponent extends OrderPageComponent {
  protected override createPersonalForm() {
    const personalData = this.orderStateService.getPersonalData();
    const agreements = new FormArray([], requiredFormArray());

    const checkList = this.orderAgreements.map((a) => {
      const item = new CheckListItemModel(a.id, a.name);
      item.required = a.required;
      item.content = a.contents;
      return item;
    });

    console.log(checkList);
    checkList.forEach((c) => {
      agreements.push(new FormControl(c));
    });

    if (checkList.length) {
      this.form = new FormGroup(
        {
          email: new FormControl(personalData.email, [Validators.required, Validators.pattern(ValidatorHelper.emailPattern)]),
          agreements: agreements,
          pickUpType: new FormControl('myself'),
        },
        {}
      );
    } else {
      this.form = new FormGroup(
        {
          email: new FormControl(personalData.email, [Validators.required, Validators.pattern(ValidatorHelper.emailPattern)]),
          pickUpType: new FormControl('myself'),
        },
        {}
      );
    }
  }

  public membershipLogin() {
    const email = this.form.get('email').value;
    const dialogRef = this.dialog.open(MembershipLoginDialogComponent, {
      data: { email: email },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderStateService.setMembershipCard(result.card);
      }
    });
  }

  protected createPaymentRequest(): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = this.orderState.cinema.id;
    orderPaymentRequest.orderId = this.orderState.order.id;
    orderPaymentRequest.paymentChannel = this.env.payment.channel;

    orderPaymentRequest.paymentProviderIdentifier = this.getPaymentProviderEnum();
    orderPaymentRequest.intPayMethodType = this.selectedPaymentMethod.id;

    orderPaymentRequest.savePayment = false;

    return orderPaymentRequest;
  }

  closeOrderByPayment() {
    const paymentProvider = this.getPaymentProviderEnum();
    const deletePayments$ = this.orderStateService.hasExternalPayments() ? this.orderStateService.clearExternalPayments(paymentProvider) : of(null);

    deletePayments$
      .pipe(
        switchMap(() => {
          //for vivawallet
          return this.orderDataProvider.getOrderPaymentMethod(this.orderState.cinemaId, this.orderState.orderId).pipe(
            tap((providers) => {
              const provider = providers.find((provider) => provider.identifier === paymentProvider);
              this.selectedPaymentMethod = new PaymentProviderPayMethodViewModel({ id: provider.id } as PaymentProviderPayMethodApiModel);
            })
          );
        }),
        switchMap(() => {
          return this.orderDataProvider.getOrderPayment(this.createPaymentRequest());
        })
      )
      .subscribe({
        next: (payment) => {
          this.paymentProxyComponent.onPostInitPayment(payment, this.getPaymentProvider()?.identifier);
        },
        error: (e) => {
          // this.onPaymentProcessChanged(PaymentProcessEnum.FAILED);
          console.log(e);
        },
      });
  }

  unassignCard() {
    if (this.orderState) {
      this.cardDataProvider.deleteCardFromOrder(this.orderState.cinemaId, this.orderState.orderId).subscribe((order) => {
        this.orderStateService.setOrder(order);
        this.orderStateService.setMembershipCard(null);
      });
    }
  }

  updateBasketAndCloseOrderByPayment(el: HTMLElement) {
    this.loadPaymentMethods = false;
    this.afterUpdateBasket = () => {
      this.closeOrderByPayment();
    };
    this.updateBasket();
  }
}
