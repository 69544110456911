export abstract class ErrorHelper {
  public static hasError(error: any, code: number) {
    return this.checkCode(error, code) || this.checkCode(error.error, code) || this.checkCode(error.error.error, code);
  }

  public static getMessage(error: any, code: number = null) {
    return this.getError(error, code)?.message;
  }

  public static getCode(error: any) {
    return this.getError(error)?.code;
  }

  public static getError(error: any, code: number = null) {
    if (!error) {
      return null;
    } else if (error.error) {
      return this.getError(error.error, code);
    }

    return this.checkCode(error, code) ? error : null;
  }

  private static checkCode(error: any, code: number): boolean {
    return error && error.code !== undefined && (code === null || error.code === code);
  }
}
