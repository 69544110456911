import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { catchError, first, iif, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { getKeyByValueBitwise } from '../../helpers/map.helper';
import { UpdateOrderRequest } from '../../models/update-order-request.model';
import {
  CardDataProvider,
  CinemaViewModel,
  OrderDataProvider,
  OrderViewModel,
  PaymentMethodViewModel,
  UseGiftCardRequestModel,
} from '@dinein-lib/restapi-plugin';
import { ENVIRONMENT_TOKEN, OrderStateService, VoucherService } from '@dinein-lib/core';

export enum PromoCodeProcessStatusEnum {
  BEGIN = 'BEGIN',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  EMAIL_REQUIRED = 'EMAIL_REQUIRED',
}

export interface IDiscountGiftStateModel {
  status: PromoCodeProcessStatusEnum;
  data?: any;
  error?: any;
}

export enum CardPaymentEnum {
  GiftCard = 'Gift Card',
  Prepaid = 'Prepaid',
  Loyalty = 'Loyalty',
  Discount = 'Discount',
}

const paymentTypesMap = new Map<CardPaymentEnum, number>([
  [CardPaymentEnum.GiftCard, 32],
  [CardPaymentEnum.Prepaid, 4],
  [CardPaymentEnum.Discount, 2],
  [CardPaymentEnum.Loyalty, 1],
]);

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
})
export class PromoCodeComponent implements OnInit {
  @Input() public cinema: CinemaViewModel;
  @Input() public order: OrderViewModel;
  @Input() public readonly: boolean;
  @Input() public disabled = false;
  @Input() public tryUseGiftCard: boolean;
  @Input() public email: string;

  @Output() public stateChanged = new EventEmitter<IDiscountGiftStateModel>();

  public number: string = null;

  paymentMethods: PaymentMethodViewModel[];
  internalPaymentTypes: Map<string, string> = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private voucherService: VoucherService,
    private cardDataProvider: CardDataProvider,
    private orderStateService: OrderStateService,
    private orderDataProvider: OrderDataProvider
  ) {
    const internalPaymentTypeIdForGiftCard = environment.payment.giftCardPaymentTypeId;
    const internalPaymentTypeIdForPrePaid = null;

    this.internalPaymentTypes = new Map()
      .set(CardPaymentEnum.GiftCard, internalPaymentTypeIdForGiftCard)
      .set(CardPaymentEnum.Prepaid, internalPaymentTypeIdForPrePaid);

    if (this.orderStateService.getState()?.cinemaId && this.orderStateService.getState()?.orderId) {
      this.orderDataProvider
        .getOrderPaymentMethod(this.orderStateService.getState().cinemaId, this.orderStateService.getState().orderId)
        .pipe(first())
        .subscribe((s) => (this.paymentMethods = s));
    }
  }

  ngOnInit(): void {
    this.number = '';
  }

  public use(): void {
    if (!this.number.length) {
      return;
    }

    this.stateChanged.emit({ status: PromoCodeProcessStatusEnum.BEGIN, data: null });

    const orderNotExist = this.orderStateService.orderNotExist();
    if (orderNotExist) {
      return;
    }

    const hasUserEmail = this.orderStateService.orderHasUserEmail();
    const useGiftCard = this.cardDataProvider.info(this.number).pipe(
      mergeMap((cardInfo) => {
        const request = this.order.paymentMethods.filter((x) => x.cardId).map((x) => new UseGiftCardRequestModel(x.cardId, x.id));
        request.push(this.makeUseGiftCardRequestModel(cardInfo?.id, cardInfo?.type));
        return this.cardDataProvider.useGiftCard(this.cinema.id, this.order.id, request);
      }),
      tap((res) => {
        this.orderStateService.setOrder(res);
        this.number = '';
      }),
      switchMap((res) => {
        return this.orderDataProvider
          .postOrderClose(this.cinema.id, res.id, false)
          .pipe(switchMap(() => this.orderDataProvider.getOrder(this.cinema.id, this.order.id)));
      })
    );

    const checkEmail = iif(
      () => hasUserEmail,
      useGiftCard,
      of(this.email ?? null).pipe(
        switchMap((email) => {
          if (email) {
            this.orderStateService.setPersonalData({
              email: email,
              phone: '',
            });

            return this.orderDataProvider
              .putOrder(this.cinema.id, this.order.id, {
                userEmail: email,
              } as UpdateOrderRequest)
              .pipe(switchMap((order) => useGiftCard));
          } else {
            this.stateChanged.emit({ status: PromoCodeProcessStatusEnum.EMAIL_REQUIRED });
            return of(null);
          }
        })
      )
    );

    this.voucherService
      .assignVoucherToOrderViaApiModel(this.cinema.id, this.order.id, this.number)
      .pipe(catchError(() => iif(() => this.tryUseGiftCard === true, checkEmail, of(null))))
      .subscribe({
        next: (data) => {
          this.stateChanged.emit({ status: PromoCodeProcessStatusEnum.SUCCESS, data: data });
        },
        error: (error: HttpErrorResponse) => {
          this.stateChanged.emit({ status: PromoCodeProcessStatusEnum.FAILED, error: error });
        },
      });
  }

  public makeUseGiftCardRequestModel(cardId: string, cardTypeNumber: number) {
    return new UseGiftCardRequestModel(cardId, this.getPaymentTypeIdByCardType(cardTypeNumber));
  }

  getPaymentTypeIdByCardType(cardType: number) {
    const result = this.paymentMethods?.find((p) => p.name === getKeyByValueBitwise(paymentTypesMap, cardType))?.id;
    return result ?? this.getPaymentTypeId(cardType);
  }

  getPaymentTypeId(cardType: number) {
    if (this.isPrepaid(cardType)) {
      return this.internalPaymentTypes.get(CardPaymentEnum.Prepaid);
    } else if (this.isGiftCard(cardType)) {
      return this.internalPaymentTypes.get(CardPaymentEnum.GiftCard);
    }

    return null;
  }

  public isPrepaid(type: number) {
    const prepaidType = paymentTypesMap.get(CardPaymentEnum.Prepaid);
    return (prepaidType & type) === prepaidType;
  }

  public isGiftCard(type: number) {
    const giftCardType = paymentTypesMap.get(CardPaymentEnum.GiftCard);
    return (giftCardType & type) === giftCardType;
  }
}
