import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderStateModel } from '../../models/order.state.model';
import { OrderStateService } from '../../services/order.state.service';
import { SearcherService } from '../../services/searcher.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CateringService } from '../../services/catering.service';
import { Router } from '@angular/router';
import { LocationEnum } from '../../models/location.enum';
import { storageKey } from '../../app.consts';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { CateringAggregationArticleViewModel, CinemaViewModel } from '@dinein-lib/restapi-plugin';
import { LoadingService, SearchResultComponent } from '@dinein-lib/shared';
import { BasePageComponent } from '../base.page.component';

@Component({
  template: '',
})
export class ProductsPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('searchResult') searchResult: SearchResultComponent;
  modalRef?: BsModalRef;

  hide = false;
  groupsChanges = this.searchService.groupsChanges;
  queryChanges = this.searchService.queryChanges;

  orderItems: CateringAggregationArticleViewModel[] = [];

  private subscription: Subscription;

  totalValue = 0;
  totalItems = 0;

  public hasQuery = false;

  hasOrderContent = false;
  storedLocation: LocationEnum = LocationEnum.UNDEFINED;
  public locationEnum: typeof LocationEnum = LocationEnum;

  public env;

  public cinema: CinemaViewModel | null = null;

  public display = '';
  public readyToUse = false;

  lastQuery: string = null;

  sem = true;
  public rules_url: string;

  public faQrcode = faQrcode;
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    public searchService: SearcherService,
    private cateringService: CateringService,
    private modalService: BsModalService,
    private loadingSerivce: LoadingService,
    private router: Router
  ) {
    super();

    this.env = environment;
    this.rules_url = environment.rulesUrl;
  }

  ngOnInit(): void {
    const canDisplayWelcomePage = sessionStorage.getItem(storageKey.welcomePage) !== 'true' && this.env.app.displayWelcomePage;
    this.storedLocation = this.orderStateService.getStoredUserLocation();
    if (this.storedLocation === LocationEnum.CINEMA && canDisplayWelcomePage) {
      this.cinema = this.orderStateService.getCinema();
      this.loadingSerivce.hideLoader();
      this.display = 'welcome';
    }

    if (this.storedLocation === LocationEnum.UNDEFINED) {
      this.display = 'scaner';
    }

    if (this.storedLocation === LocationEnum.CINEMA && !canDisplayWelcomePage) {
      this.display = 'offer';
    }

    this.subscription = this.orderStateService.state$.subscribe((state) => {
      if (state && !canDisplayWelcomePage) {
        if (!state?.order) {
          if (this.orderStateService.isCinemaStored()) {
            this.orderStateService.loadState();
          } else {
            this.hasOrderContent = false;
          }
        } else {
          this.display = 'offer';
          this.hasOrderContent = true;
          this.searchService.search(this.lastQuery);
          this.updateCartState(state);
        }
      }
    });

    this.subscription.add(
      this.queryChanges.subscribe((query) => {
        this.hasQuery = !!query?.length;
        if (this.searchResult && this.lastQuery !== query) {
          this.lastQuery = query;
          setTimeout(() => {
            if (this.hasQuery) {
              this.searchResult.expandAll();
            } else {
              this.searchResult.collapsAll();
            }
          }, 200);
        }
      })
    );
  }

  updateCartState(state: OrderStateModel) {
    if (!state.order) {
      return;
    }

    this.cateringService.buildSelectedCateringArticleCombinationList(state.cinema, state.order, []).subscribe((articles) => {
      this.orderItems = articles;
      this.totalItems = state.order.getItemCount();
      this.totalValue = state.order.getPrice();
      this.loadingSerivce.hideLoader();

      this.readyToUse = true;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'call-service-modal',
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  runScanner() {
    sessionStorage.setItem(storageKey.welcomePage, 'true');
    this.orderStateService.cleanStateAll();
    this.cinema = null;
    this.router.navigate(['scanner']);
  }

  goNext() {
    sessionStorage.setItem(storageKey.welcomePage, 'true');
    this.orderStateService.loadState();

    setTimeout(() => {
      window.location.reload();
      //this.router.navigate(['offer']);
    }, 10);
  }
}
