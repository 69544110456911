// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('../assets/version.json').number + '-dev',
  production: false,
  projectName: 'cineland',
  title: 'DineIn Cineland',
  brand: {
    fullLogo: '',
    subLogo: '',
  },
  globalize: {
    country: 'BG',
    currency: 'BGN',
    language: {
      default: 'bg',
      available: ['bg'],
      cultureMap: [{ key: 'bg', value: 'bg-BG' }],
    },
  },
  app: {
    showUpdatePrompt: true,
    showInstallPrompt: true,
    checkNewVersionInterval: 60,
    displayWelcomePage: true,
  },
  api: {
    url: '',
    securityKey: 'api_1blRKjNQQeViYnEt32wSFiw9aBR',
  },
  baseImgOnError: './assets/images/D.svg',
  tips: [
    { type: 'fixed', value: 0 },
    { type: 'percent', value: 5 },
    { type: 'percent', value: 10 },
    { type: 'percent', value: 15 },
    { type: 'manual', value: 0 },
  ],
  payment: {
    provider: 'vivawallet',
    giftcard: '',
    channel: 'web',
    paymentRedirectUrl: '',
    allowedPaymentMethod: ['vivawallet'],
    autoSelectedPaymentMethod: 'vivawallet',
    canUseStoredPaymentMethod: false,
    giftCardPaymentTypeId: '',
  },
  features: {
    callForService: false,
  },
  rulesUrl: '',
  theme: 'dark',
  order: {
    orderAgreementGroupId: '',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
