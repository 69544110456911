<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="flex-start" class="pt-1">
  <ng-container *ngIf="hasAnyItems() else nothingToShowTemplate">
    <mat-accordion class="example-headers-align" multi displayMode="flat" hideToggle="false" togglePosition="before">
      <ng-container *ngFor="let group of groups">
        <ng-container *ngIf="group.articles?.length">
          <mat-expansion-panel [id]="group.id" [expanded]="isExp(group.id)" (afterExpand)="propertyChange(group.id)"
                               (afterCollapse)="propertyChange(group.id)">
            <mat-expansion-panel-header collapsedHeight="64px" expandedHeight="64px">
              <mat-panel-title>
                <div class="group-bar d-flex justify-content-between align-items-center w-100">

                  <div class="group-bar-label">{{group.name}}</div>
                  <div class="group-bar-img">
                    <img *ngIf="group.graphicUrl" [src]="group.graphicUrl">
                  </div>
                </div>

              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngFor="let article of group.articles">
              <app-article-card [item]="updatedArticle(article)"></app-article-card>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
    </mat-accordion>
  </ng-container>
</div>


<ng-template #nothingToShowTemplate>
  <p class="pt-3">
    {{'app.search.no-item-found' | translate}}
  </p>
</ng-template>