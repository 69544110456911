import { AfterViewInit, Component, inject } from '@angular/core';
import { CinemaViewModel, ScreeningDetailsViewModel } from '@dinein-lib/restapi-plugin';
import { OrderStateService } from '../services/order.state.service';

@Component({
  template: '',
})
export class BasePageComponent implements AfterViewInit {
  public orderStateService = inject(OrderStateService);
  public screeningDetailsModel: ScreeningDetailsViewModel;
  public cinemaModel: CinemaViewModel;

  ngAfterViewInit(): void {
    this.screeningDetailsModel = this.orderStateService.getScreeningInfo();
    this.cinemaModel = this.orderStateService.getCinema();
  }
}
