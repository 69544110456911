import { CUSTOM_ELEMENTS_SCHEMA, Injector, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENVIRONMENT_TOKEN } from './injection.tokens';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserModule } from '@angular/platform-browser';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { OrderStateService } from './services/order.state.service';
import { AppConfigService } from './config/app-config.service';
import { AppInstallUpdateService } from './config/app-install-update.service';

import { MessagePageComponent } from './pages/message/message.component';
import { OrderPageComponent } from './pages/order/order.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaymentPageComponent } from './pages/payment/payment.component';
import { ProductDetailsPageComponent } from './pages/product-details/product-details.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { RoutingHubComponent } from './pages/routing-hub/routing-hub.component';
import { ScannerComponent } from './pages/scanner/scanner.component';
import { SharedModule } from '@dinein-lib/shared';
import { ApiPluginModule } from '@dinein-lib/restapi-plugin';
import { SimpleCountdownComponent } from './components/simple-countdown/simple-countdown.component';
import { BasePageComponent } from './pages/base.page.component';

@NgModule({
  declarations: [
    ScannerComponent,
    ProductDetailsPageComponent,
    OrderPageComponent,
    ProductsPageComponent,
    PaymentPageComponent,
    RoutingHubComponent,
    PageNotFoundComponent,
    MessagePageComponent,
    SimpleCountdownComponent,
    BasePageComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    HttpClientModule,
    ZXingScannerModule,
    NgxQRCodeModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    ApiPluginModule,
  ],
  exports: [
    //modules
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ZXingScannerModule,
    NgxQRCodeModule,

    ScannerComponent,
    ProductDetailsPageComponent,
    OrderPageComponent,
    ProductsPageComponent,
    PaymentPageComponent,
    RoutingHubComponent,
    PageNotFoundComponent,
    MessagePageComponent,
    SimpleCountdownComponent,
  ],
  providers: [AppConfigService, AppInstallUpdateService, OrderStateService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private injector: Injector) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(environment: any, providers: Provider[] = []): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: ENVIRONMENT_TOKEN,
          useValue: environment,
        },
        ...[providers],
      ],
    };
  }
}
