import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { iif, noop, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { OrderStateModel } from '../../models/order.state.model';
import { CateringService } from '../../services/catering.service';
import { NotificationService } from '../../services/notification.service';
import { OrderStateService } from '../../services/order.state.service';
import { PaymentService } from '../../services/payment.service';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { TranslateService } from '@ngx-translate/core';
import { PaymentProviderPayMethodRequestModel } from '../../models/request/payment-provider-pay-method.request.model';
import { OrderPaymentRequestModel } from '../../models/order-payment.request.model';
import { MatDialog } from '@angular/material/dialog';
import { VoucherService } from '../../services/voucher.service';
import { UpdateOrderRequest } from '../../models/update-order-request.model';
import { Router } from '@angular/router';
import {
  AgreementType,
  AgreementViewModel,
  CardDataProvider,
  CateringAggregationArticleGroupViewModel,
  CateringAggregationArticleViewModel,
  OrderDataProvider,
  OrderStatus,
  OrderViewModel,
  PaymentMethodApiModel,
  PaymentMethodViewModel,
  PaymentProviderPayMethodViewModel,
  ScreeningDetailsViewModel,
  UserDataProvider,
} from '@dinein-lib/restapi-plugin';
import {
  ChecklistComponent,
  DonePaymentProviderEvent,
  ErrorHelper,
  ErrorPaymentProviderEvent,
  IDiscountGiftStateModel,
  LoadingService,
  NotSelectedPaymentProviderEvent,
  OrderHelper,
  PaymentComponent,
  PaymentProcessEnum,
  PaymentProviderEvent,
  PaymentProxyComponent,
  PickUpType,
  PromoCodeProcessStatusEnum,
  ProviderEnum,
  ValidatorHelper,
  WaitPaymentProviderEvent,
  WorkPaymentProviderEvent,
} from '@dinein-lib/shared';
import { BasePageComponent } from '../base.page.component';

export function isOrder(model: OrderViewModel | any): model is OrderViewModel {
  return (model as OrderViewModel).id !== undefined;
}

@Component({
  templateUrl: './order.component.html',
})
export class OrderPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('promoCode') public promoCode: ElementRef;
  @ViewChild(PaymentComponent) public paymentComponent: PaymentComponent;
  @ViewChild(PaymentProxyComponent) public paymentProxyComponent: PaymentProxyComponent;

  protected typeToken = 'TOKEN';
  private subscription: Subscription;
  public pickUpType: typeof PickUpType = PickUpType;

  step = 0;
  groups: CateringAggregationArticleGroupViewModel[] = [];
  articles: CateringAggregationArticleViewModel[] = [];
  orderState: OrderStateModel = null;
  totalValue = 0;
  totalItems = 0;
  isPaymentProcess = false;
  env: any;
  form: FormGroup = null;
  giftCardForm: FormGroup = null;
  savePayment = false;
  paymentButtonFlow = false;
  paymentMethodList: PaymentProviderPayMethodViewModel[] = [];
  storedPaymentMethodList: PaymentProviderPayMethodViewModel[] = [];
  selectedPaymentMethod: PaymentProviderPayMethodViewModel;
  orderAgreements: AgreementViewModel[] = [];
  canUseStoredPaymentMethod = false;
  membershipCard: any;

  loadPaymentMethods = false;

  @ViewChild(ChecklistComponent) agreementComponent: ChecklistComponent;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected cateringService: CateringService,
    protected orderDataProvider: OrderDataProvider,
    protected loadingService: LoadingService,
    protected notificationService: NotificationService,
    protected paymentService: PaymentService,
    protected cardDataProvider: CardDataProvider,
    protected translation: TranslateService,
    protected voucherService: VoucherService,
    protected dialog: MatDialog,
    protected userDataProvider: UserDataProvider,
    protected router: Router,
    private orderHelper: OrderHelper
  ) {
    super();
    this.env = environment;
    this.canUseStoredPaymentMethod = environment.payment.canUseStoredPaymentMethod;
  }

  protected closeOrderByPayment() {
    noop();
  }
  protected createPaymentRequest(): OrderPaymentRequestModel {
    return new OrderPaymentRequestModel();
  }
  protected afterUpdateBasket() {
    noop();
  }

  ngOnInit(): void {
    this.subscription = this.orderStateService.state$.subscribe((state) => {
      if (!this.isPaymentProcess) {
        this.updateCartState(state);
      }
    });

    this.screeningDetailsModel = this.orderStateService.getScreeningInfo();

    this.userDataProvider
      .agreementGet(AgreementType.ORDER)
      .pipe(
        tap((agreements) => {
          this.orderAgreements = agreements.marketingAgreements;
        })
      )
      .subscribe({
        next: () => this.createPersonalForm(),
      });

    console.log('ngOnInit order');
    this.orderStateService.getMembershipCard().subscribe((card) => {
      console.log('card', card);
      this.membershipCard = card;
    });

    this.orderHelper.updateOrderMembershipCard().subscribe();
  }

  protected createPersonalForm() {
    const personalData = this.orderStateService.getPersonalData();

    this.form = new FormGroup(
      {
        phone: new FormControl(personalData.phone, [Validators.required, Validators.pattern(ValidatorHelper.phoneRegex)]),
        email: new FormControl(personalData.email, [Validators.required, Validators.email]),
      },
      {}
    );
  }

  updateCartState(state: OrderStateModel) {
    this.orderState = state;
    if (state && state.order) {
      this.cateringService.buildSelectedCateringArticleCombinationList(state.cinema, state.order, []).subscribe((articles) => {
        this.articles = articles;
        this.totalItems = state.order.getItemCount();
        this.totalValue = state.order.getPrice();
      });
    } else {
      window.location.href = '/';
    }
  }

  public discountGiftStateChanged(event: IDiscountGiftStateModel) {
    console.log(event.status);

    switch (event.status) {
      case PromoCodeProcessStatusEnum.BEGIN:
        break;
      case PromoCodeProcessStatusEnum.PROCESSING:
        break;
      case PromoCodeProcessStatusEnum.FAILED:
        this.voucherService.voucher = null;
        this.notificationService.showError(ErrorHelper.getMessage(event.error));
        break;
      case PromoCodeProcessStatusEnum.SUCCESS:
        if (event.data && isOrder(event.data)) {
          this.voucherService.voucher = event.data.voucher;
          this.orderStateService.setOrder(event.data);
          if (event.data.status === OrderStatus.FINISHED) {
            this.redirectToPaymentSummary(this.orderState.cinemaId, this.orderState.orderId);
          }
        }
        break;
      case PromoCodeProcessStatusEnum.EMAIL_REQUIRED:
        this.form.get('email').setErrors({ required: true });
        break;
      default:
        break;
    }
  }

  public startPaymentProcess() {
    if (this.paymentComponent?.selectedPaymentMethod) {
      //  this.loadingServide.showLoader();
      if (this.paymentComponent.submitButton) {
        this.paymentComponent.submitButton.nativeElement.click();
      }
    } else if (this.selectedPaymentMethod) {
      this.loadingService.showLoader();
      this.closeOrderByPayment();
    } else {
      this.notificationService.showError(this.translation.instant('app.page.payment.choosePaymentMethod'));
    }
  }

  public updateBasket() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.showLoader();
    const formData = this.setPersonalData();

    const cinemaId = this.orderState.cinemaId;
    const orderId = this.orderState.orderId;

    this.orderDataProvider
      .putOrder(cinemaId, orderId, {
        userEmail: this.orderState.order.userEmail,
        userPhone: this.orderState.order.userPhone,
      } as UpdateOrderRequest)
      .pipe(
        switchMap(() =>
          iif(
            () => this.orderStateService.isTableStored() === true && formData.pickUpType === PickUpType.BRING_ME,
            this.orderDataProvider.putTable(cinemaId, orderId, this.orderStateService.getTableId()),
            of(null)
          )
        ),
        switchMap(() => {
          const filteredAgreements = formData.agreements?.filter((a) => a.checked).map((a) => a.id);
          if (!filteredAgreements?.length) {
            return of(null);
          }

          return this.orderDataProvider.putOrderAgreementss(this.orderState.cinemaId, this.orderState.orderId, filteredAgreements);
        }),
        switchMap(() => {
          if (this.env.payment.provider === 'fake') {
            return this.paymentService.getOrderPayment(this.orderState);
          } else if (this.loadPaymentMethods) {
            return this.orderDataProvider
              .getPaymentProviderPayMethodCollection(
                new PaymentProviderPayMethodRequestModel(
                  this.orderState.cinemaId,
                  this.orderState.orderId,
                  this.getPaymentProviderEnum(),
                  this.env.payment.channel
                )
              )
              .pipe(
                tap((paymentMethods) => {
                  this.paymentMethodList = paymentMethods?.filter((o) => o.type !== this.typeToken);
                  this.storedPaymentMethodList = paymentMethods?.filter((o) => o.type === this.typeToken);
                  if (this.storedPaymentMethodList.length) {
                    this.selectedPaymentMethod = this.storedPaymentMethodList[0];
                  }
                })
              );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (res) => {
          this.isPaymentProcess = true;
          this.agreementComponent?.setDisabledState(true);
          this.afterUpdateBasket();
        },
        error: (e: HttpErrorResponse) => {
          if (ErrorHelper.hasError(e, 101)) {
            this.paymentService.setEmbeddedContent();
          } else {
            this.orderStateService.onError(e);
          }

          this.loadingService.hideLoader();
        },
        complete: () => {
          this.loadingService.hideLoader();
        },
      });
  }

  private setPersonalData() {
    const formData = this.form.getRawValue();
    const email = formData['email'] ?? '';
    const phone = formData['phone'] ?? '';

    this.orderState.order.userEmail = email;
    this.orderState.order.userPhone = phone;
    this.orderStateService.setPersonalData({
      email: email,
      phone: phone,
    });
    return formData;
  }

  getTipMaxValue() {
    return Math.floor(this.totalValue);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public get isSpecialPaymentMethod(): boolean {
    return this.selectedPaymentMethod && ['PAY', 'APL'].includes(this.selectedPaymentMethod.id);
  }

  public onSelectPaymentMethod(paymentMethod) {
    if (!this.paymentButtonFlow) {
      this.selectPaymentMethod(paymentMethod);
    }
  }

  public onPaymentProcessChanged(event: PaymentProcessEnum) {
    switch (event) {
      case PaymentProcessEnum.BEGIN:
        this.loadingService.showLoader();
        break;
      case PaymentProcessEnum.FAILED:
      case PaymentProcessEnum.END:
        this.loadingService.hideLoader();
        break;
    }
  }

  private selectPaymentMethod(paymentMethod) {
    this.selectedPaymentMethod = paymentMethod;
    this.savePayment = false;
  }

  getPaymentProvider(): PaymentMethodViewModel {
    return new PaymentMethodViewModel(Object.assign(new PaymentMethodApiModel(), { identifier: this.getPaymentProviderIdentifier() }));
  }

  getPaymentProviderIdentifier(): string {
    return this.environment.payment.autoSelectPaymentMethod || this.environment.payment.allowedPaymentMethod[0];
  }

  getPaymentProviderEnum(): ProviderEnum {
    return this.getPaymentProviderIdentifier() as ProviderEnum;
  }

  public onPaymentProviderEvent(event: PaymentProviderEvent): void {
    console.log('#@onPaymentProviderEvent', event);
    if (event instanceof WaitPaymentProviderEvent) {
      this.loadingService.showLoader();
    }

    if (event instanceof WorkPaymentProviderEvent) {
      this.loadingService.showLoader();
    }

    if (event instanceof DonePaymentProviderEvent) {
      this.loadingService.hideLoader();
    }

    if (event instanceof ErrorPaymentProviderEvent) {
      this.loadingService.hideLoader();
    }

    if (event instanceof NotSelectedPaymentProviderEvent) {
      this.loadingService.hideLoader();
    }
  }

  redirectToPaymentSummary(cinemaId, orderId) {
    window.history.replaceState({}, '', '/');
    this.router.navigate(['payment'], { queryParams: { params: `${orderId},${cinemaId}` } });
  }
}
