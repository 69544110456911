<mat-card class="card card-article" id="{{item.id}}" [class.hidden]="hide">
  <div class="product-list-item">
    <ng-container>
      <div class="product-list-detail d-flex gap-2" (click)="navigateToDetails()">
        <ng-container *ngTemplateOutlet="dataTemplate;"></ng-container>
      </div>
    </ng-container>

    <ng-template #dataTemplate>
      <img *ngIf="item.graphicUrl" [src]="item.graphicUrl" appHideMissing appLazy>
      <div class="d-inline-block">
        <div class="product-name">{{ item.name }}</div>
        <div class="product-description">
          {{ item.description }}
        </div>
      </div>
    </ng-template>

    <div class="product-list-action">
      <ng-container [ngTemplateOutlet]="quantityTpl"></ng-container>
      <ng-container *ngIf="!summaryContext">
        <span class="product-price" *ngIf="item.price || item.comboMinPrice">
          {{ (item.price ?? item.comboMinPrice) | money: ''}}
        </span>
      </ng-container>
      <ng-container *ngIf="summaryContext">
        <span class="product-price">
          <ng-container *ngTemplateOutlet="totalPriceTemplate; context: { item: item }"></ng-container>
        </span>
      </ng-container>
      <button mat-raised-button color="primary"
              *ngIf="!disabled && (hasAnyMods || !totalQuantity || hasVoucheredItems(item.orderArticles))"
              (click)="add()" class="mt-auto"> +
      </button>
    </div>
  </div>

  <mat-card-footer *ngIf="totalQuantity > 0">
    <ng-container *ngFor="let itemArticle of item.orderArticles; let ind = index;">

      <div *ngIf="hasAnyMods"
           class="product-list-item product-list-subitem px-3 py-1 product-list-detail d-flex align-items-center">
        <!-- <h5 class="d-inline-block group-label">{{modifier.name}}</h5> -->
        <div class="d-flex flex-column">

          <div class="product-name-small">{{ item.name }}</div>
          <div *ngIf="itemArticle.modifierArticleList &&  itemArticle.modifierArticleList.length"
               class="product-description">
            {{ modifiers(itemArticle) }}
          </div>
          <div *ngIf="itemArticle.subArticleList &&  itemArticle.subArticleList.length" class="product-description">
            {{ subarticles(itemArticle) }}
          </div>
        </div>
        <div class="product-list-action">
          <div class="counter">
            {{ 'app.page.order.total-items' | translate : { totalItems: itemArticle.selectedQuantity } }}
          </div>
          <span class="product-price">
            <ng-container *ngTemplateOutlet="itemPriceTemplate; context: { item: itemArticle }"></ng-container>
          </span>
        </div>
      </div>

      <ng-container *ngIf="!disabled">
        <ng-container *ngIf="!itemArticle.voucherNumber else voucherRowTemplate">
          <div class="quantity-picker d-flex justify-content-between">
            <button mat-button (click)="decrement(itemArticle)">-</button>
            <ng-container *ngIf="summaryContext">
              <div class="pt-2 mt-1">
                <span *ngIf="item.price || item.comboMinPrice">
                  {{ (item.price ?? item.comboMinPrice) | money: ''}}
                </span>
              </div>
            </ng-container>
            <button mat-button (click)="increment(itemArticle)">+</button>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #voucherRowTemplate>
        <div class="d-flex justify-content-between">
          <div class="mx-3 my-2">
            {{itemArticle.selectedQuantity}}x {{item.name}} (<b>{{itemArticle.voucherName}}</b>)
            <span><ng-container
                            *ngTemplateOutlet="itemPriceTemplate; context: { item: itemArticle }"></ng-container></span>
          </div>
          <button mat-button (click)="delete(itemArticle)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </ng-template>
    </ng-container>
  </mat-card-footer>

</mat-card>

<ng-template #itemInCart>
  <mat-icon class="mat-icon product-in-cart" color="primary">check</mat-icon>
</ng-template>

<ng-template #itemPriceTemplate let-item="item">
  <ng-container *ngIf="!item.discountPrice else diffPriceTemplate">
    {{ item.price | money: ''}}
  </ng-container>
  <ng-template #diffPriceTemplate>
    <span class="discount-price-box"><span class="before">{{ item.discountPrice.before | money: ''}}</span>&nbsp;<span
            class="after">{{ item.discountPrice.after | money: ''}}</span></span>
  </ng-template>
</ng-template>

<ng-template #totalPriceTemplate let-item="item">
  <ng-container *ngIf="!item.discountPrice else diffPriceTemplate">
    {{ (item.price * totalQuantity) | money }}
  </ng-container>
  <ng-template #diffPriceTemplate>
    <span class="discount-price-box"><span class="before">{{ item.discountPrice.before | money }}</span>&nbsp;<span
            class="after">
        {{ (item.discountPrice.after) | money }}</span></span>
  </ng-template>
</ng-template>

<ng-template #quantityTpl>

  <div class="counter" *ngIf="totalQuantity && !hasAnyMods">
    {{ 'app.page.order.total-items' | translate : { totalItems: totalQuantity } }}
  </div>
</ng-template>