<div class="header header-fixed">
  <mat-toolbar>
    <ng-container>
      <button mat-button [routerLink]="['/offer']">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="title" [style.width.%]="100">
        {{ 'app.page.order.your-order' | translate }}
      </div>
      <app-simple-countdown></app-simple-countdown>
    </ng-container>
  </mat-toolbar>

  <mat-toolbar class="details">
    <app-screening-details [details]="screeningDetailsModel" [cinema]="cinemaModel"></app-screening-details>
  </mat-toolbar>

  <ng-template #newOrderButtonTemplate>
    <button mat-button [routerLink]="['/']">
      <mat-icon>chevron_left</mat-icon>
      <span class="title px-3">{{ 'button.new-order' | translate }}</span>
    </button>
  </ng-template>
</div>

<div class="container watermark" [class.watermark]="step !== 2">
  <ng-container>
    <ng-container *ngIf="articles">
      <ng-container *ngFor="let item of articles">
        <app-article-card [item]="item" [mode]="'order'" [disabled]="isPaymentProcess" [summaryContext]="true">
        </app-article-card>
      </ng-container>

    </ng-container>

    <div class="row my-3 mx-1">
      <div class="col-12 box d-flex">
        <div class="box-left">
        </div>
        <div class="box-right">
          <div class="counter">
            {{ 'app.page.order.total-items' | translate : { totalItems: totalItems } }}
          </div>

          <ng-container *ngIf="orderState && orderState.order && orderState.order.defaultExtraFees">
            <div *ngFor="let fee of orderState.order.defaultExtraFees">
              <span>{{ fee.defaultExtraFeeName }}: </span>
              <span class="price-value">
                {{ fee.defaultExtraFeePrice | money }}
              </span>
            </div>
          </ng-container>

          <div class="price">
            <span>{{ 'app.page.order.to-pay' | translate }}</span>
            <span class="price-value">
              {{ totalValue | money }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-3 mx-1" *ngIf="voucherService.voucher">
      <div class="col-12 box d-flex">
        <div class="box-left">
          <h2>
            {{ 'app.page.order.voucher' | translate : { name: voucherService.voucher.voucherName } }}
          </h2>
          <p>
            {{ voucherService.voucher.voucherDescription }} <br />
            {{ 'app.page.order.voucher-valid' | translate : { date: voucherService.voucher.voucherExpiryDate | dateTime : 'DATE_SHORT_DMY' } }}
          </p>
        </div>
        <div class="box-right">
          <!-- <img
            mat-card-sm-image
            *ngIf="foundVoucher?.picture"
            [src]="foundVoucher.picture"
          /> -->
        </div>
      </div>
    </div>

    <mat-card>
      <mat-card-content>
        <h5 class="group-label">{{ 'label.additional-info' | translate }}</h5>
        <ng-container *ngIf="!voucherService.voucher">
          <app-promo-code [cinema]="orderState.cinema" [order]="orderState.order" [readonly]="isPaymentProcess"
                          [tryUseGiftCard]="true" (stateChanged)="discountGiftStateChanged($event)"
                          [email]="form?.get('email').value"></app-promo-code>
        </ng-container>

        <div class="row">
          <div class="col-12 mb-3">
            <ng-container *ngIf="voucherService.voucher else beforeCardUsedInfo">
              {{ "app.page.order.after-card-used" | translate }}
            </ng-container>
            <ng-template #beforeCardUsedInfo>
              {{ "app.page.order.before-card-used" | translate }}
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="form">
          <form [formGroup]="form" autocomplete="off">
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ 'label.email' | translate }}</mat-label>
                  <input type="email" matInput formControlName="email"
                         [placeholder]="'label.ex' | translate : { value: 'john@world.com' }" autocomplete="off"
                         required [readonly]="isPaymentProcess || membershipCard" />
                  <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')"
                             [innerHtml]="'app.page.order.email-valid' | translate"></mat-error>
                  <mat-error *ngIf="form.get('email').hasError('required')"
                             [innerHtml]="'app.page.order.email-required' | translate"></mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="!membershipCard && !isPaymentProcess">
              <div class="col-12">
                <button mat-raised-button color="primary" (click)="membershipLogin()"
                        [disabled]="form?.get('email').invalid">
                  {{ 'button.membership-login' | translate }}
                </button>
              </div>
            </div>

            <ng-container *ngIf="membershipCard">
              <div class="row">
                <div class="col-12">
                  <div class="membership-card d-flex justify-content-between align-items-center pl-3">
                    <div class="membership-card-header-image p-2"></div>
                    <span class="mt-2">{{membershipCard.name}}</span>
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="unassignCard()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="col-12 text-green mt-2">
                  {{'app.page.order.membership-card-info' | translate}}
                </div>
              </div>
            </ng-container>

            <div class="pb-5" *ngIf="form.value?.agreements?.length">
              <app-checklist #agreementComponent [value]="form.value?.agreements"
                             formControlName="agreements"></app-checklist>
            </div>

            <div class="mt-2" [ngClass]="{'d-none': !orderStateService.isTableStored()}">
              <h5 class="group-label">
                {{ 'label.pick-up-type' | translate }}
              </h5>

              <mat-button-toggle-group formControlName="pickUpType" class="w-100">
                <mat-button-toggle [value]="pickUpType.MYSELF"
                                   class="w-50">{{'app.page.order.pick-up-type.myself' | translate}}</mat-button-toggle>
                <mat-button-toggle [value]="pickUpType.BRING_ME"
                                   class="w-50">{{'app.page.order.pick-up-type.bring-me' | translate}}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </form>
        </ng-container>

      </mat-card-content>
    </mat-card>

    <ng-container *ngIf="isPaymentProcess && (storedPaymentMethodList?.length || paymentMethodList?.length)">
      <mat-card>
        <mat-card-content>

          <h5 class="group-label">{{ 'app.page.payment.choosePaymentMethod' | translate }}</h5>

          <ng-container *ngIf="canUseStoredPaymentMethod && storedPaymentMethodList?.length">
            <h5>{{ 'app.page.payment.stored' | translate }}</h5>
            <div class="d-flex flex-column payment-btns">
              <ng-container *ngFor="let paymentMethod of storedPaymentMethodList">
                <ng-container *ngIf="paymentMethod.canBeShown">
                  <div class="mb-1 w-100">
                    <button mat-raised-button [color]="selectedPaymentMethod === paymentMethod ? 'primary' : ''"
                            (click)="onSelectPaymentMethod(paymentMethod)">
                      <div>{{ paymentMethod.name + " " + paymentMethod.cardMask }}</div>
                      <div class="pl-3">{{ "app.page.payment.cardExpirationDate" | translate | uppercase }}
                        {{ paymentMethod.cardExpirationMonth }}/{{ paymentMethod.cardExpirationYear }}
                      </div>
                    </button>
                  </div>

                </ng-container>
              </ng-container>
            </div>
            <hr>
          </ng-container>

          <ng-container *ngIf="paymentMethodList?.length">
            <div class="d-flex flex-column payment-btns">
              <ng-container *ngFor="let paymentMethod of paymentMethodList">
                <ng-container *ngIf="paymentMethod.canBeShown">
                  <button mat-raised-button [color]="selectedPaymentMethod === paymentMethod ? 'primary' : ''"
                          (click)="onSelectPaymentMethod(paymentMethod)">
                    {{ paymentMethod.name }}
                  </button>
                </ng-container>
              </ng-container>
            </div>

            <div class="mt-2" *ngIf="canUseStoredPaymentMethod">
              <mat-checkbox [checked]="savePayment" (change)="savePayment = !savePayment">
                {{ "app.page.payment.savePayment" | translate }}
              </mat-checkbox>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>

    </ng-container>

  </ng-container>

  <div #target class="divider"></div>
</div>

<div class="footer footer-extra row" *ngIf="!isPaymentProcess">
  <div class="col-12">
    <button *ngIf="articles?.length" class="action-button" id="payment-trigger" mat-raised-button color="primary"
            (click)="updateBasketAndCloseOrderByPayment(target)" [disabled]="!form?.valid || !articles?.length">
      {{ 'button.pay' | translate }}
    </button>
    <button *ngIf="!articles?.length" class="action-button" mat-raised-button color="primary" [routerLink]="['/']">
      {{ 'button.back' | translate }}
    </button>
  </div>
</div>

<!-- <div class="footer footer-extra row" *ngIf="isPaymentProcess">
  <div class="col-12">
    <button class="action-button" id="payment-trigger" mat-raised-button color="primary" (click)="startPaymentProcess()"
            [disabled]="!form?.valid || !articles?.length">
      {{ 'button.pay' | translate }}
    </button>
  </div>
</div> -->

<app-payment-proxy-component #paymentProxyComponent [paymentMethod]="getPaymentProvider()"
                             (providerEvent)="onPaymentProviderEvent($event)">
</app-payment-proxy-component>