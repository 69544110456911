import { Component } from '@angular/core';
import { SimpleCountdownComponent } from '@dinein-lib/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-simple-countdown',
  templateUrl: './simple-countdown.component.html',
})
export class CustomSimpleCountdownComponent extends SimpleCountdownComponent {}
