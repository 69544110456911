import { Component } from '@angular/core';
import { PaymentPageComponent } from '@dinein-lib/core';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment.component.html',
})
export class CustomPaymentPageComponent extends PaymentPageComponent {
  createNewOrder() {
    this.orderStateService.cleanState();
    this.router.navigate(['offer']);
  }
}
